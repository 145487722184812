import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Image from "gatsby-image"
import SEO from "../components/seo"

const PartsPage = ({ data }) => (
  <Layout>
    <SEO title="H1 Parts" keywords={[`H1 Parts`]} />
    <section className="container section">
      <h2 className="subtitle is-2">Parts For Sale</h2>
      <div className="table-container">
        <table className="parts-table table is-fullwidth is-striped">
          <thead>
            <tr>
              <th></th>
              <th>
                <abbr title="Part">Part</abbr>
              </th>
              <th>
                <abbr title="Desc">Description</abbr>
              </th>
              <th>
                <abbr title="Qty">In Stock</abbr>
              </th>
              <th>
                <abbr title="Price">Price</abbr>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.allWordpressWpParts.edges.map(part => (
              <tr key={part.node.wordpress_id}>
                <td>
                  <Link
                    to={`/part/${part.node.slug}`}
                    style={{
                      display: "flex",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    {part.node.featured_media && (
                      <Image
                        fixed={
                          part.node.featured_media.localFile.childImageSharp
                            .fixed
                        }
                        alt={part.node.title}
                      />
                    )}
                  </Link>
                </td>

                <td>
                  {part.node.title && (
                    <Link to={`/part/${part.node.slug}`}>
                      <span
                        dangerouslySetInnerHTML={{ __html: part.node.title }}
                      />
                    </Link>
                  )}
                </td>
                <td>
                  {part.node.excerpt && (
                    <span
                      dangerouslySetInnerHTML={{ __html: part.node.excerpt }}
                    />
                  )}
                </td>
                <td>
                  {part.node.acf.product_quantity && (
                    <span className="tag is-medium">
                      {part.node.acf.product_quantity}
                    </span>
                  )}
                </td>
                <td>
                  {part.node.acf.product_price && (
                    <span className="tag is-medium">
                      ${part.node.acf.product_price}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  </Layout>
)

export default PartsPage

export const query = graphql`
  query {
    allWordpressWpParts {
      edges {
        node {
          wordpress_id
          title
          excerpt
          slug
          featured_media {
            localFile {
              childImageSharp {
                fixed(height: 64) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          acf {
            product_quantity
            product_price
          }
        }
      }
    }
  }
`
